@import './App.css';
@import './index.css';
@import './scheduler.scss';
@import './dashboard.scss';  

::-webkit-scrollbar {
    width:6px;
    border-radius:10px;
}

::-webkit-scrollbar-track {
    -webkit-border-radius:5px;
    border-radius:5px;
    background:rgba(0,0,0,0.1);
}
 
::-webkit-scrollbar-thumb {
    -webkit-border-radius:5px;
    border-radius:5px;
    background:rgba(0,0,0,0.2);
}

::-webkit-scrollbar-thumb:hover {
    background:rgba(0,0,0,0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
    background:rgba(0,0,0,0.05);
    visibility: hidden;
}