.scheduler-calender {
  padding-top: 8px;
  background-color: #fff;

  .dropdown {
    display: inline-block;
    margin-right: 10px;
    width: 100%;
    &.show {
      .dropdown-toggle {
        background-color: #41b6e6 !important;
        border-color: #41b6e6 !important;
      }
    }
    .dropdown-toggle {
      padding: 5px 10px;
      font-size: 14px;
      background-color: #fff;
      color: #808080;
      border-color: #808080;
      border-radius: 4px;
      width: 100%;
    }
    .dropdown-menu {
      &.show {
        // width: 100%;
      }
    }
  }

  // .fc-day-grid-event.fc-day-grid-event {
  //   background-color: #41b6e6;
  //   border: 1px solid #41b6e6;
  //   font-size: 12px;
  //   padding: 1px 0;
  //   &.empty {
  //     padding: 0 !important;
  //     margin: 0;
  //     height: 0;
  //     width: 0;
  //   }
  // }
  // .fc-day-grid-event.placeholder {
  //   font-size: 12px;
  //   padding: 1px 0 !important;
  // }
  .scheduler-calendar {
    // padding-top: 20px;

    // .fc-left {
    //   button {
    //     background-color: #ffffff !important;
    //     border: 1px solid #ffffff !important;
    //     padding: 0 4px !important;
    //     &:focus {
    //       box-shadow: none !important;
    //     }
    //     .fc-icon {
    //       font-size: 15px !important;
    //     }
    //   }
    // }
    
    // .fc-right {
    //   .fc-button {
    //     padding: 4px 7px !important;
    //     &:hover {
    //       background-color: #41b6e6 !important;
    //       color: #ffffff !important;
    //       border-color: #41b6e6 !important;
    //     }
    //     &:focus {
    //       box-shadow: none !important;
    //     }
    //     &.fc-button-active {
    //       background-color: #41b6e6 !important;
    //       color: #ffffff !important;
    //       border-color: #41b6e6 !important;
    //     }
    //   }
    }

    // .fc-view {
    //   .fc-head {
    //     th {
    //       background-color: #007ad9 !important;
    //       color: #ffffff !important;
    //     }
    //   }
    // }
  // }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #6c757d;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1);
    font-size: 12px !important;
  }

  .MuiSelect-selectMenu.MuiOutlinedInput-input {
    padding: 10px 14px !important;
    height: 13px !important;
    min-height: 13px;
    max-height: 30px;
    font-size: 12px !important;
  }
}

.MuiSelect-selectMenu.MuiOutlinedInput-input {
  padding: 10px 14px !important;
  height: 13px !important;
  min-height: 13px;
  max-height: 30px;
  font-size: 12px !important;
}

.create-button {
  font-size: 20px !important;
}

.days-available {
  color: #1b84c6 !important;
  font-weight: 500 !important;
  font-size: 10px !important;
}
.days-not-available {
  color: #3e3e3e !important
}

.scheduler-sidebar {
  // border-right: 1px solid #ddd;
  padding: 0px !important;
  background-color: #fff;
  overflow: auto;
  height: 89vh;
  >div {
    height: 100%;
  }

  .sidebar-heading {
    color: #137bc0 !important;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .accordian {
    padding-bottom: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .MuiExpansionPanel-root.Mui-expanded:last-child {
      flex: 1 !important;
    }
    .MuiPaper-elevation1 {
      box-shadow: none !important;
      margin: 0 !important;

      &:before {
        opacity: 0 !important;
      }

      .MuiExpansionPanelSummary-expandIcon {
        .MuiSvgIcon-root {
          width: 1.5em !important;
          height: 1.5em !important;
          color: #a9a9a9 !important;
        }
      }

      .MuiExpansionPanelSummary-root {
        padding: 23px 0 !important;
        border-bottom: 0 !important;
        border-top: 0 !important;
        min-height: 30px !important;
        height: 30px;

        .MuiExpansionPanelSummary-content {
          margin: 0px 0 !important;
          p {
            font-size: 16px;
            color: #3e3e3e;
            margin-bottom: 4px;
            display: inline-block;
          }
        }
      }

      &:nth-child(3) {
        .MuiExpansionPanelDetails-root {
          height: 100%;
        }
      }

      .MuiExpansionPanelDetails-root {
        padding: 4px 0 4px 0 !important;
        min-height: 0;
        // max-height: 130px;
        overflow-y: auto;
        display: flow-root;
        label {
          .MuiIconButton-root {
            padding: 2px 2px 2px 10px !important;
          }
        }
        .MuiSvgIcon-root {
          color: #137bc0 !important;
          width: 1.5em !important;
          height: 1.5em !important;
        }

        .MuiFormControlLabel-root {
          .MuiTypography-root {
            font-size: 1.3rem !important;
            color: #6b6b6b;
          }
        }
      }
    }
  }

  #external-events {
    // .fc-event {
    //   border-radius: 3px;
    //   border: 1px solid #41b6e6 !important;
    //   font-size: 13px;
    //   padding: 7px 11px;
    //   margin: 4px 0;
    //   background-color: #41b6e6;
    // }
  }

  .button-primary {
    display: inline-block;
    margin-right: 3px;
    width: 49%;
    box-shadow: none;
    border-radius: 3px;
    background-color: #41b6e6;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 300;
    margin-top: 5px;
    &:hover {
      background-color: #0da0dc;
    }
  }

  .button-default {
    display: inline-block;
    width: 49%;
    box-shadow: none;
    border-radius: 3px;
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 300;
    margin-top: 5px;
    &:hover {
      background-color: #929292;
    }
  }
}

.MuiFormControl-root {
  width: 100%;
}

.calendar-nav li {
  border-right: 1px solid #ccc;
  margin-right: 10px;
  padding-right: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  cursor: pointer;
  color: #939393;
}
.calendar-nav li:hover {
  color: #41b6e6;
}
.calendar-nav li:last-child {
  border-right: none;
  padding: 0;
  margin: 0;
}
.calendar-nav li .fa {
  margin-right: 5px;
}

.date-to-date-dropdown {
  display: inline-block;
  width: 100%;
}
// .fc-unthemed .fc-content {
//   background-color: initial !important;
//   border-color: initial !important;
// }

.scheduler-calendar {
  display: inline-block;
  height: 100%;
  // overflow: auto;
  // width: 93.5%;
}

.calendar-nav .MuiToggleButton-root.Mui-disabled {
  // color: #3e3e3e !important;
}

.create-block-main {
  width: 76px;
  height: 100%;
  border-left: 1px solid #ddd;
  position: fixed;
  right: 0;
  top: 75px;
  background-color: #fff;
  .create-button {
    width: 70px;
    margin: 15px auto;
    text-align: center;
    padding: 0px 0;
    cursor: pointer;
    button {
      background-color: transparent;
      border: 0;
      &:focus {
        outline: 0;
      }
    }
    .create-logo {
      font-size: 25px;
      line-height: 25px;
      svg {
        color: #1b84c6 !important;
      }
    }
    p {
      font-size: 12px;
      color: #999;
      margin-top: 1px;
      margin-bottom: 0;
      line-height: 21px;
    }
  }
}
.scheduler-sidebar .accordian .MuiPaper-elevation1 .MuiExpansionPanelDetails-root {
  // min-height: 300px;
  box-shadow: none !important;
}
.scheduler-sidebar .accordian .MuiExpansionPanel-root.Mui-expanded:last-child{
  overflow: auto;
}

.scheduler-sidebar .accordian .MuiExpansionPanel-root.Mui-expanded {
  overflow: hidden;
}

.scheduler-calender .MuiToggleButton-sizeSmall {
  min-height: 30px !important;
  height: 33px !important;
}

.MuiToggleButton-sizeSmall {
  min-height: 25px !important;
  height: 25px !important;
}

.accordian .MuiToggleButtonGroup-groupedSizeSmall {
  padding: 0px 19px 0px 19px !important;
  text-align: center !important;
}

.open-shift-skill-modal .MuiFormControl-root {
  // width: 50%;
  // margin-left: 25%;
}

.action-button-calender {
  padding-top: 17px;
  z-index: 999;
  position: absolute;
}

// Modal Styles

.modal-main {
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiBackdrop-root {
    background: none;
  }

  .modal-paper {
    // border: 2px solid #000;
    // padding: 16px 32px 24px;
    box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    border-radius: 5px;
    width: 600px;
    min-height: 320px;
    padding: 0;
    position: relative;
    padding-bottom: 47px;
    &.modal-popper {
      width: 430px;
      min-height: 240px;
      // .modal-header {
      //   background-color: #ffffff;
      //   border-color: #ffffff;
      //   padding: 0.8rem 1rem;
      //   #modal-title {
      //     color: #6b6b6b;
      //     font-size: 18px;
      //     margin: 4px 0;
      //   }
      //   svg {
      //     top: 14px;
      //     font-size: 19px;
      //     color: #6b6b6b !important;
      //   }
      // }
      .modal-body {
        margin: 2px;
        .modal-row {
          margin-bottom: 13px;
          &:after {
            content: "";
            clear: both;
            display: table;
          }
          select {
            display: inline-block;
            width: 150px;
            // float: right;
            margin-right: 4px;
            font-size: 12px;
          }
          label {
            display: inline-block;
            // float: right;
            margin-right: 11px;
            font-size: 14px;
            font-weight: 100;
            margin-right: 12px;
            margin-top: 2px;
            color: #6b6b6b;
            // width: 95px;
          }
          .repeat-cycle {
            // float: right;
            margin-top: 7px;
            font-size: 12px;
          }
        }
      }
    }
    .modal-header {
      justify-content: left;
      background-color: #40b6e6;
      border-color: #40b6e6;
      padding: 1.8rem 1rem;
      position: relative;
      svg {
        font-size: 24px;
        color: #fff !important;
        position: absolute;
        right: 20px;
        cursor: pointer;
      }
      #modal-title {
        color: #fff;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.6px;
      }
    }
    .modal-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border: 0;
      padding-bottom: 20px;
      padding-right: 20px;
      button {
        background-color: #137cc0;
        font-size: 14px;
        padding: 4px 15px;
        text-transform: capitalize;
      }
      .MuiButton-containedPrimary {
        margin-right: 5px;
      }
      .MuiButton-containedSecondary {
        margin-right: 5px;
      }
    }
  }
}

.create-block-datepicker {
  .date-picker {
    width: 35%;
    display: inline-block;
    margin-right: 30px;
    .MuiGrid-container {
      .MuiFormLabel-root {
        font-size: 16px;
      }
      #date-picker-dialog {
        font-size: 14px;
      }
    }
  }
}
.add-skill {
  font-size: 20px !important;
  float: right;
  margin-right: 35px;
}

.modal-skills-constainer {
  max-height: 220px;
  overflow-y: auto;
  // height: 124px;
  margin-top: 0px;
}

.modal-sills-list {
  margin-top: 14px;
  position: relative;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .delete-skill {
    position: absolute;
    right: 13px;
    top: 7px;
    font-size: 15px;
    svg {
      color: #f16f6f !important;
    }
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 13px) scale(1);
  }
  .MuiSelect-root {
    height: 35px;
    .MuiSelect-selectMenu {
      padding-top: 12px;
      padding-bottom: 8px;
    }
  }

  .modal-skill-dropdown {
    width: 44%;
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
    float: left;
    select {
      height: 35px;
    }
  }
  .modal-skill-input {
    width: 30%;
    display: inline-block;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    width: 35%;

    input {
      height: 35px;
    }
  }
  .modal-skill-button {
    display: inline-block;
    float: left;
    width: 20%;
    margin-top: 5px;
  }
}


.MuiBottomNavigationAction-wrapper {
  border: none;
}

.side-triangle {
  border-bottom: solid 1rem transparent;
        border-left: solid 1rem white;
        border-top: solid 1rem transparent;
}

.MuiAccordion-root.Mui-expanded {
  // flex: 1 !important;
}

// .fc-view .fc-dayGridMonth-view .fc-dayGrid-view > table {
//   max-height: 40px !important;
// }
.MuiAccordion-root.Mui-expanded:last-child {
  margin-bottom: 0;
  margin-top: 4px;
}

.MuiAccordion-root.Mui-expanded:first-child {
  margin-top: 0;
  margin-bottom: 4px;
}


// .fc-day{
//     button {
//       // color: #FFFFFF;
//       position: absolute;
//       bottom: 0px;
//       display: none;
//     }
//     &:hover {
//       button:not([disabled]) {
//         display: block;
//       }
//     }
// }


// .fc-day > button {
//   color: red;
// }

// // display element on hover

// .fc-day:hover {
//   button {
//     color: white;
//   }
// }


.MuiSelect-icon {
  top: unset !important;
}

.MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.fc-event-qhp {
  border: 1px solid #41b6e6;
    background-color: transparent !important;
  margin-bottom: 4px;
  border-radius: 0px !important;;
  border-left: 6px solid #41b6e6 !important;
  margin: 2px 4px !important;
  padding-left: 4px;
  font-size: 1rem !important;
  height: 2rem;
  >div{
    font-weight: 700;
    
  color: #41b6e6 !important;
  }
  &:hover {
    background-color: #41b6e6 !important;
    >div{
      color: #fff !important;
    }
    
  }
}

.MuiButton-containedPrimary {
  border: 1px solid #007ad9;
  background: #007ad9 !important;
}

.MuiButton-outlinedPrimary {
  border: 1px solid #007ad9;
}

.fc  th {
  background-color: #007ad9 !important;
  color: #ffffff !important;
}


.schedular-add-button {
  height: 1.5rem;
  width: 1.5rem;
  // position: absolute !important;
  // bottom: 5px !important;
  // left: 15px !important;
  min-width: unset !important;
  
    display: flex !important;
    width: auto !important;
  font-weight: 700;
  // display: none;
  // :not([disabled]) {
  // }
}


// .fc-row {
//   &:hover .slider {
//     display: block !important;
//   }
// }


.slider {
  display: inline-block;
  position: absolute;
  width: 30px;

  height: 20px;
  font-weight: 700;
  color: #007ad9 !important;
  // bottom: 0px;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  z-index: 999999999;
  &[disabled=true] {
    display: none;
  }
}

.slide-1 {
  // position: absolute;
  // top:0; left:0;
  // border-radius: 50%;
  // border: 2px solid red;
  height: 100%;
  width: 100%;
  z-index: 999999999;
display:inline-block;
  font-size: 1.5em;
  transition: all .5s;
}
.slider.slider-left:hover .slide-1 { left: -100%; opacity:0; }

.slide-2 {
  position: absolute;
  height: 100%;
  width: 100%;
  font-size: 1.2em;
  transition: all .5s;
display:inline-block;
  opacity: 0;
}
.slider.slider-left .slide-2 { left: 100%; }
.slider.slider-left:hover .slide-2 { left: 0; opacity: 1; }


.MuiGrid-grid-sm-12 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.day-available-button-group {
  .MuiButtonBase-root {
    background: #D0E6F4 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;;
    color: white !important;
    :hover , :active , :focus {
      background: #D0E6F4 !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;;
    }
  }
  .MuiButton-containedPrimary {
    background: #57C7EE !important;
    color: white !important;
    font-weight: 700 !important;
    :hover , :active , :focus {
      background: #57C7EE !important;
    }
    
  }
}

.time-range-availability {
  height: 2rem;
  background: #57C7EE;
  color: white;
  width: 100%;
  border-radius: 4px;
  font-size-adjust: 1rem;;
  justify-content: center;
    align-items: center;
    display: flex
}

.MuiFormLabel-root {
  font-size: 1.5em !important;
}


.available-qsp {
  padding: 0px !important;
  height: 20px;;
  flex: 0 !important;
}

.MuiCollapse-entered {
  flex: 1 !important;
  .MuiCollapse-wrapper {
    height: 100%;
    .MuiCollapse-wrapperInner {
      >div {
        height: 100%;
      }
    }
  }
  /* color: red; */
}


.fc-center {
  margin-top: 1rem !important;
  height: 2rem !important;
  display: flex;
  // border: 1px solid red !important;
  button {
      padding: 0px 4px !important;
  }
  h2 {
    font-size: 2em !important;
    margin: 0px 2rem !important;
    color: #939393 !important;
    font-weight: 400;
  }
}

// .scheduler-calendar {
//   &[deepview=false] {
//     button {
//       color: red !important;
//     }
//   }
// }

.MuiFormControl-marginDense {
  margin: 0px !important;
}


.container-filedset {
    border: 1px solid silver;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.54);
    padding: 4px;;
    &--label {
      font-weight: 400;
      width: auto;
      margin: 0px;
      margin-left: 1.1rem;
    font-size: 1rem;
    }
    &--data {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
}

.MuiPickersDay-day {
  &:active, &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.react-tabs {
  width: 100% !important;
  
}
  .MuiGrid-container > .MuiGrid-item {
    >div[class$="-container"] {
      width: 100%;
    }
  }

.shceduler-main .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.team {
  .team-schedule-blocks tbody {
    display: block;
    max-height: 140px;
    overflow-y: scroll;
  }
  
  .team-schedule-blocks thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .team-schedule-blocks head, tbody th td {
    width: 25%;
  }
  .team-schedule-blocks head {
    width: calc( 100% - 0.5em );
  }
}

.fc-myTitle-button {
  color: #939393 !important;
  background-color: #FFF !important;
  border: 1px solid #FFF !important;
  font-size: 14px !important;
}
.fc-more-cell {
  // padding: 5px !important;
  // font-size: 12px !important;
}