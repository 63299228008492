.dashboard-container {
    padding: 20px;
    background: #ffffff;
    .chart-img {
        margin-bottom: 30px;
        img {
            max-width: 100%;
        }
    }
}
h2.dashboard-title {
    font-size: 36px;
    font-weight: 500;
}

.dashboard-date {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
}
.table-wrapper-main {
    .table {
        border: 1px solid #dddddd;
        >thead {
            >tr {
                >th {
                    padding-left: 20px;
                    font-size: 14px;
                    background: #007AD9;
                    font-weight: 400;
                    border: 0;
                }
            }
        }
        tbody {
            >tr {
                >td {
                    font-size: 12px;
                    // padding: 10px 20px;
                }
            }
        }
    }
}

.heade-dots {
    float: right;
    cursor: pointer;
}

.calendar {
    padding-right: 7px;
    display: inline-block;
}

.per-diem-spend-box {
    background-color: #007AD9;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.box-title {
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
}

.box-title.title-inline {
    display: block;
} 

.per-diem-price {
    display: block;
    font-size: 36px;
    color: #ffffff;
    font-weight: 700;
}

.table-call-1 {
    // width: 36%;
    padding-left: 10px;
    padding-right: 10px;
}

.table-call-2 {
    // width: 32%;
    padding-left: 10px;
    padding-right: 10px;
}

.table-call-3 {
    // width: 32%;
    padding-left: 10px;
    padding-right: 10px;
}
.table-call-4 {
    // width: 32%;
    padding-left: 10px;
    padding-right: 10px;
}


.box-svg {
    margin: 0 5px;
}

.top-providers {
    background-color: #007AD9;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    border: 1px solid #D2D2D2;
}

.providers-title {
    font-size: 14px;
    display: block;
    color: #ffffff;
    font-weight: 100;
}


.no-of-providers {
    border: 1px solid #D2D2D2;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    .providers-title { 
        color: #68BC00;
        &.text-red {
            color:#D22828;
        }
        &.text-green{
            color: #68BC00;
        }
    }
}

.providers-price {
    font-size: 24px;
    font-weight: 700;
    &.text-green {
        color: #68BC00;
    }
}

.no-of-title {
    font-size: 14px;
    display: block;
    font-weight: 600;
    color: #2e2f30;
}

.view-link {
    font-size: 14px;
    color: #007AD9;
}

.providers-boxs {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;    
    .col-providers {
        width: calc(50% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
    }
}

.generic-dashboard-container {
    padding-top: 10px;
    background: #ffffff;
}
h2.generic-dashboard-title {
    font-size: 36px;
    font-weight: 500;
}