html {
  height: 100%;
}
body {
  background-image: url(./../../bgsignIn.png);
  background-position: -125px 10px;
  background-size: 900px 854px;
  background-origin: content-box;
  background-repeat: repeat-y;
  background-color: #fff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  line-height: 1.5;
  overflow-x: hidden;
  padding-right: 0px !important;
}

.logo {
  width:150px;
  padding-left: 15px;
}
.logo img {
  width:100%;
  display:block;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #6e7274;
}
/* body, html {
  background-color: #002366 !important;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @media only screen and (min-width: 500px) {
  .MediaQuery-mobile{
      display: none;
  }
}

@media only screen and (max-width: 500px) {
  .MediaQuery-desktop{
      display: none;
  }
} */

.swal2-container {
  z-index: 100000 !important;
}

.sidenav---sidenav---_2tBP {
  background: #fff !important;
  border-right: 1px solid #e4e4e4;
}

.sidenav---sidenav---_2tBP .route-link {
  display: block;
}
.sidenav---sidenav---_2tBP .route-link .sidenav---sidenav-navitem---uwIJ- {
  clear: both;
  position: relative;
  padding: 8px 0;
}

.sidenav---sidenav---_2tBP
  .route-link
  .sidenav---sidenav-navitem---uwIJ-
  .sidenav---navitem---9uL5T {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.sidenav---sidenav---_2tBP
  .route-link
  .sidenav---sidenav-navitem---uwIJ-
  .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo {
  display: block;
  float: left;
  width: 64px;
  height: 50px;
  margin-right: -6px;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  opacity: 0.7;
  line-height: 50px;
  text-align: center;
}
.sidenav---sidenav---_2tBP
  .route-link
  .sidenav---sidenav-navitem---uwIJ-
  .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  display: inline-block;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
  .route-link
  .sidenav---sidenav-navitem---uwIJ-
  .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  display: inline-block;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
  .route-link
  .sidenav---sidenav-navitem---uwIJ-
  .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  display: inline-block;
}
.sidenav---sidenav---_2tBP
  .route-link
  .sidenav---sidenav-navitem---uwIJ-
  .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  display: none;
}

.MuiAppBar-colorPrimary {
  /* color: #07BEF9   !important; */
  /* background-color: #04001F !important; */
  color: #fff !important;
  background: linear-gradient(
    90deg,
    rgba(27, 132, 198, 1) 65%,
    rgba(64, 182, 230, 1) 100%
  );
}

.stepper {
  background-color: #fb6005 !important;
}
.slide-pane {
  background: #fff !important;
  min-width: 100px !important;
  height: 95% !important;
  margin-top: 86px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5) !important;
}
.sidenav---sidenav-toggle---1KRjR {
  color: #fd7e14;
}

.sidenav---sidenav---_2tBP .route-link .sidenav---sidenav-navitem---uwIJ- .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo{
  width: 85px !important;
  font-size: 20px;
}

.sidenav---sidenav---_2tBP {
  z-index: 999 !important;
}

/* .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {

  background-color: #1b84c6 !important;
} */

.MuiTableCell-head {
  color: #000000 !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}

.slide-pane__header {
  background: linear-gradient( 90deg, rgb(32, 139, 202) 38%, rgba(64, 182, 230, 1) 100% ) !important;
  border-color: #1b84c6;
}
.slide-pane__close {
  color: #fff;
}
.slide-pane .slide-pane__title {
  font-size: 20px;
  font-weight: bold;
  max-width: 90%;
  color: #fff !important;
}
.logoStyle {
  margin-left: 5%;
  position: fixed;
}
.faiconsStyle {
  color: #1b84c6 !important;
}
.popperStyle {
  margin-left: 90%;
}
.buttonStyle {
  margin-left: 95%;
  color: #fff;
  font-size: 400%;
}
.footer-text {
  /* margin-left: 45%; */
  font-size: 16px;
  text-align: center;
  height: 70px;
  color: #fff;
  right: 0;
  vertical-align: middle;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

/* .MuiInputBase-input {
  
  color: currentColor;
  background: #ffff;
 
} */
/* .fc-event, .fc-event-dot {
  background-color: #F90745 !important;
} */
.MUIDataTable-responsiveScroll {
  max-height: none;
}

/* Footer Styles */

.fixed-bottom{
  height: 85px !important;
  z-index: 999;
  text-align: CENTER;
  background: linear-gradient( 90deg, rgba(27, 132, 198, 1) 65%, rgba(64, 182, 230, 1) 100% );
  padding: 30px 0;
  color: #fff;
}

.fixed-bottom .MuiToolbar-root{
  justify-content: center;
  min-height: 85px !important;
}

/* Header Styles */

.header-main {
  position: fixed;
  width: 100%;
  z-index: 999;
  top:0;
  left:0
}
.header-main .MuiPaper-elevation4 {
  box-shadow: none;
  height: 50px !important;
}

.header-main .MuiPaper-elevation4 .MuiToolbar-root{
  /* min-height: 85px !important; */
}

.main-navigation .nav-wrapper {
  border: 0;
  position: fixed;
  min-width: 85px;
}


@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 48px !important;
  }
}


.main-navigation .nav-wrapper .toggle-button {
  background-color: #1b84c6;
  width: 100% !important;
  position: relative;
  height: 50px !important;
}

.main-navigation .nav-wrapper .toggle-button span {
  margin: 5px 28px;
  background-color: #fff;
  width: 30px !important;
}
.nav-wrapper.sidenav---expanded---1KdUL .toggle-button span {
  margin: 2px 31px;
}

.main-navigation .nav-wrapper .menubar {
  height: 100%;
  border-right: 1px solid #e8e8e8;
}

.main-container{
  height: 100%;
  margin-left: 85px;
  padding: 0 0 0 16px;
  margin-top: 50px;
  /* background-color: #fff; */
}

.table-main{
  padding-top: 100px;
}

.table-main .add-strip{
  position: fixed;
  top: 85px;
  width: 100%;
  left: 85px;
  background-color: #fff;
  padding: 15px 30px;
  /* z-index: 50; */
}

.table-main .add-strip .add-icon{
  display: inline-block;
  cursor: pointer;
}

.table-main .add-strip .add-icon h3{
  display: inline-block;
  font-size: 21px;
  font-weight: 400;
  color: #717171;
}

.table-main .add-strip .add-icon i{
  display: inline-block;
    border: 1px solid #5fc0e5;
    font-size: 12px;
    margin-left: 6px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    color: #68bfe0;
    vertical-align: super;
    padding-top: 2px;
}

.table-wrapper{
  box-shadow: none !important;
}
.table-wrapper .p-datatable .p-datatable-scrollable-header, body .p-datatable .p-datatable-scrollable-footer {
  background-color: #ffffff !important;
}

.table-wrapper thead tr th{
  padding: 1em 0.857em !important;
  border-top:0 !important;
  border-left:0 !important;
  border-right:0 !important;
  border-bottom: 1px solid #e2e2e2 !important;
  font-weight: 400 !important;
  color: #b5b5b5 !important;
  background-color: #fff !important;
  text-align: left !important;
}

.table-wrapper tbody tr{
  background: #ffffff !important;
  color: #333333 !important;

}

.table-wrapper tbody tr td{
  background: inherit;
  border-top:0 !important;
  border-left:0 !important;
  border-right:0 !important;
  border-bottom: 1px solid #e2e2e2 !important;
  padding: 1em 0.857em !important;
  color: #757575 !important;

}

/* Panel Styles */

.MuiOutlinedInput-input{
  font-size: 14px !important;
  height: 1em !important;
}


/* Modal Styles */

.button-wrapper{
  width: 98%;
  margin: 0px auto;
}

.button-wrapper.primary{
  margin-top: 15px;
  margin-bottom: 15px;
}

.button-wrapper .button-primary{
  width: 100%;
  background-color: #33a5db;
  padding: 8px 0;
  font-size: 14px;
  box-shadow: none;  
}
.button-wrapper .button-primary:hover{
  background-color: #3eb3e4 !important;
}

.button-wrapper .button-cancel{
  width: 100%;
  padding: 8px 0;
  font-size: 14px;
  background-color: #ff6262;
  box-shadow: none;
}


/* .fc-unthemed .fc-content{
  padding: 2px 5px;
  background-color: #41b6e6;
  border-color: #41b6e6;
  text-align: left;
  color: #fff;
} */
/* .fc-row.fc-widget-header{
  height:20px !important;
  border-width: 0;
  border-bottom-width: 0 !important;
} */
.fc-event{
  border: 1px solid #41b6e6;
  background-color: #41b6e6;
  margin-bottom: 4px;
  font-size: 1rem !important;
  min-height: 1rem;;
  padding-left: 4px;
}
.fc .fc-row .fc-content-skeleton td {
  text-align: left;
  margin-left: 20px;
}
.fc-prev-button, .fc-next-button{
  background-image: none;
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
  padding:0;
}
/* .fc-unthemed th{
  border-bottom-color:transparent !important;
} */
/* .fc-day:hover{
  color: #000;
  background-color: #eee;
} */
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #37a8de;
  border-color: #3aabe0;
}
.calendar-nav li{
  border-right: 1px solid #ccc;
  margin-right: 10px;
  padding-right: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  cursor: pointer;
}
.calendar-nav li:hover{
  color: #41b6e6;
}
.calendar-nav li:last-child{
  border-right: none;
  padding:0;
  margin:0;
}
.calendar-nav li .fa{
  margin-right: 5px;
}
.btn{
  font-size: 13px;
  padding: 5px 10px;
}


.facility-name-list{
  background-color: #41b6e6;
  font-size: 14px;
  color: #fff;
  padding: 7px 10px;
  border-radius: 3px;
  /* margin-bottom: 6px; */
}
.facility-name-list:hover{
  background-color: #1a84c5;
}
.facility-name-list .facility-name span img{
  width: 18px;
}
.btn-wrap .btn{
  width:49%;
}
.btn-outline-secondary.dropdown-toggle:focus, 
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus{
  box-shadow: none;
}
tr:first-child>td>.fc-day-grid-event {
  margin-top: 0;
  margin-bottom: 2px;
}
/* .fc-icon-chevron-left:before{
  content: "\e900";
  color: #6c757d;
}
.fc-icon-chevron-right:before {
  content: "\e901";
  color: #6c757d;
}
.fc-right .fc-button-primary {
  color: #fff;
  background-color: #127bc0;
  border-color: #127bc0;
}
.fc-button-primary:hover,
.fc-button-primary:not(:disabled).fc-button-active,
.fc-button-primary:not(:disabled):active,
.fc-button-primary:focus,
.fc-button-primary:not(:disabled):active:focus {
  color: #fff;
  background-color: #41b6e6;
  border-color: transparent;
  box-shadow: none;
}
.fc-day-grid-container{
  height: auto !important;
}


.fc-event {
  position: relative;
  display: block;
  font-size: 1.5em;
  line-height: 1.4;
  border-radius: 3px;
  border: 1px solid #3788d8;
  padding: 9px 11px;
}

.fc-day-header{
  font-size: 10px !important;
}
.scheduler-calender .scheduler-calendar .fc-view .fc-head th {
  background-color: #1479bf !important;
}
.fc-day-top {
  font-size: 14px !important;
}

.fc-view-container .fc-view {
  height: 500px; 
  width: 99.99%;
}
.fc-toolbar.fc-header-toolbar {
  z-index: 10;
  background: white;
}

.fc-head-container.fc-widget-header {
  position: sticky;
  z-index: 10;
  background: white;
  
} */
/* skills section*/
.skills_container{
  width: 100%;
}
.skills_container table th:nth-child(2) {
  width: 30%;
}
.skills_container table tr td{
  padding: 8px !important;
  vertical-align: inherit !important
}
.css-2613qy-menu{
  /* display: block !important; */
  /* z-index: 99999 !important; */
  background: red !important
}
.submitButton {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #1479bf !important;
}

.submitButton:hover{
  background-color: #1479bf !important;
  color: #1479bf;
}

.slider-content {
  color: "#fff";
  font-size: 17;
  line-height: 25px;
  padding-top: 20px;
  text-align: left;
}

.slider-item {
  padding: "20px";
}

.slider-indicators {
  position: relative !important;
}
.carousel-indicators li {
  margin-top: "20px";
  width: 10px!important;
  height: 10px!important;
  border-radius: 50%;
  border-left: 4px solid #ffffff!important;
  border-right: 4px solid #ffffff!important;
  border-top: 4px solid #ffffff!important;
  border-bottom: 4px solid #ffffff!important;
  background-color: #3bb5e8 !important;
}

.carousel-indicators li.active {
  margin: 1px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.MuiTextField-root .Mui-error {
  font-size: 1.30rem
}



._loading_overlay_overlay.css-df17o1 {
  /* height: calc(100vh - 38px); */
  height: 100vh;
}

.select-report__control {
  height: 45px !important;
}


.is-active {
  background: red !important;
}